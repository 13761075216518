import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часто Задаваемые Вопросы на ArtPixel
			</title>
			<meta name={"description"} content={"Раскройте секреты мира дизайна – ваши вопросы, наши ответы"} />
			<meta property={"og:title"} content={"FAQ | Часто Задаваемые Вопросы на ArtPixel"} />
			<meta property={"og:description"} content={"Раскройте секреты мира дизайна – ваши вопросы, наши ответы"} />
			<meta property={"og:image"} content={"https://tovlyswift.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tovlyswift.com/img/icon1.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tovlyswift.com/img/icon1.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://tovlyswift.com/img/icon1.png"} />
			<meta name={"msapplication-TileImage"} content={"https://tovlyswift.com/img/icon1.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-8">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#555a5f" sm-margin="0px 0px 50px 0px">
				Приготовьтесь открыть для себя мир графического дизайна вместе с ArtPixel. Мы собрали ответы на самые распространенные вопросы, чтобы ваше обучение было максимально комфортным и понятным.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Какие курсы вы предлагаете?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Мы предлагаем курсы по основам графического дизайна, программному обеспечению (например, Adobe Photoshop, Illustrator), а также специализированные курсы по веб-дизайну, мобильному дизайну и UI/UX.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Нужно ли иметь предварительные знания для начала обучения?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Нет, наши курсы разработаны так, чтобы быть доступными для людей с любым уровнем подготовки, включая полных новичков.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Какие материалы мне понадобятся для курсов?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Вам понадобится компьютер и доступ в интернет. Для некоторых курсов могут потребоваться специализированные программы, о чем будет сообщено заранее.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Могу ли я применить полученные знания на практике?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Конечно, каждый курс включает в себя практические задания и проекты, которые вы сможете включить в свое портфолио.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Есть ли у вас сообщество или поддержка студентов?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Да, мы предлагаем доступ к сообществу ArtPixel, где студенты могут обмениваться опытом, получать обратную связь от преподавателей и единомышленников.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--darkL1">
						Как я могу записаться на курс?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Записаться на курс можно непосредственно на нашем сайте, выбрав интересующий вас курс и следуя инструкциям регистрации.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});